import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: "https://eu.i.posthog.com",
};

ReactDOM.render(
  <PostHogProvider
    apiKey="phc_W8nY3HYQXbs4cTcVIpgD67mfkQSBvXAPDmqoj2gYCxS"
    options={options}
  >
    <App />
  </PostHogProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
